

.notshowAcoordionIcon .accordion-button:not(.collapsed)::after,
.notshowAcoordionIcon .accordion-button::after {
  background-image: unset !important;
}

.image-upload > input {
  visibility:hidden;
  width:0;
  height:0
}


.react-datepicker__input-container input{
  width: 100%;
  border-color: #ccd2d8;
  height: 35px;
  border-radius: 4px;
}

.same-btn{
  width: 128px;
  height: 45px;
}

.same-btn-client{
  width: 110px;
}

.purchaseOrderPage .same-btn{
  width: 185px;
 
}


.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
  content: "\25B2";
  position: absolute;
  top: 2%;
  left: 45%;
  margin: auto;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:last-child:after {
  content: "\25BC";
  bottom: 1% !important;
  display: block;
  position: absolute;
  width: 100%;
}

.same-search-btn.add{
  width: 6rem;
}



.table-responsive.paymentorder table tr:last-child{
margin-bottom: 50px;

}


.table-responsive.paymentorder .selectj{
  min-width: 250px;
}
.table-responsive.paymentorder .selectk{
  min-width: 200px;
}
.server ul.pagination {
  justify-content: end;
  height: 60px;
  align-items: center;
  margin: 0 !important;
}

.input-group .input-group-text:not(:focus).is-invalid {border: 1px solid var(--bs-form-invalid-border-color)!important;}



