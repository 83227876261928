/*
Template Name: Krafts N Knits - Admin Portal Template
Author: Themesbrand
Version: 4.1.0.
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/widgets";
@import "custom/components/demos";
@import "custom/components/print";
@import "custom/components/badge";
@import "custom/components/modal";

// Plugins
@import "custom/plugins/custom-scrollbar";
@import "custom/plugins/calendar";
@import "custom/plugins/calendar-full";
@import "custom/plugins/dragula";
@import "custom/plugins/session-timeout";
@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
@import "custom/plugins/parsley";
@import "custom/plugins/select2";
@import "custom/plugins/icons/fontawesome-all";
@import "custom/plugins/switch";
@import "custom/plugins/colorpicker";
@import "custom/plugins/timepicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
@import "custom/plugins/form-upload";
@import "custom/plugins/form-wizard";
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/table-editable";
@import "custom/plugins/apexcharts";
@import "custom/plugins/echarts";
@import "custom/plugins/flot";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/google-map";
@import "custom/plugins/vector-maps";
@import "custom/plugins/leaflet-maps";


// Pages
@import "custom/pages/authentication";
@import "custom/pages/ecommerce";
@import "custom/pages/email";
@import "custom/pages/file-manager";
@import "custom/pages/chat";
@import "custom/pages/projects";
@import "custom/pages/contacts";
@import "custom/pages/crypto";
@import "custom/pages/coming-soon";
@import "custom/pages/timeline";
@import "custom/pages/extras-pages";
@import "custom/pages/jobs";


@import 'react-date-picker/dist/DatePicker.css';
@import 'react-calendar/dist/Calendar.css';

//client pages
@import "custom/pages/client";

.react-date-picker{
    width: 100%;
    .react-date-picker__wrapper{
        border: var(--bs-border-width) solid var(--bs-border-color-translucent);
        border-radius: 3px;
        padding: 0.47rem 0.75rem;
        width: 100%;
    }
}

#sidebar-menu ul li a {
    font-size: 16px;
}

.menu-title {
    padding: 12px 28px !important;
}

#sidebar-menu ul li ul.sub-menu li a {
    font-size: 15px;
}

.metismenu li {
    margin-bottom: 0.5rem;
}

li.nav-item {
    padding: 5px;
}

li.nav-item.active {
    background: transparent !important;
    border-bottom: 2px solid #556ee6 !important;
    a {
        color: #556ee6 !important;
    }
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    isolation: isolate;
    border-color: transparent;
}

.nav-link:hover, .nav-link:focus {
    color: #30374b;
}

.border-heading{
    background: #e8e8f7;
    color: #30374b;
    padding: 15px;
    margin-bottom: 0;
    border-radius: 7px 7px 0 0;
}

.border-heading-body {
    padding: 10px;
    border: 1px solid #e8e8f7;
    border-radius: 0 0 7px 7px;
}

.modal-fullscreen-xl-down {
    --bs-modal-width: 1140px;
}

.input-group{
    position: relative;
    .input-group-cross-button{
        position: absolute;
        right: 6px;
        top: 7px;
        cursor: pointer;
    }
}

.modal {
    .modal-content{
        border-radius: 5px;
    }
}

.dropdown-item {
    a {
        font-weight: 400;
        color: var(--bs-dropdown-link-color) !important;
    }
}

.input-group-cross-button.disabled {
    pointer-events: none;
    opacity: 0.6;
}

#full-width-modal-dialog {
    max-width: 90vw
}

table.contect-vetical-center tbody tr td {
    vertical-align: middle;
}